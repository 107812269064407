@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");


@font-face {
    font-family: 'Aeonik';
    src: url('/public/Fonts/Aeonik/Aeonik-Air.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/public/Fonts/Aeonik/Aeonik-AirItalic.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/public/Fonts/Aeonik/Aeonik-Thin.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/public/Fonts/Aeonik/Aeonik-ThinItalic.otf') format('opentype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/public/Fonts/Aeonik/Aeonik-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/public/Fonts/Aeonik/Aeonik-LightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/public/Fonts/Aeonik/Aeonik-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/public/Fonts/Aeonik/Aeonik-RegularItalic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/public/Fonts/Aeonik/Aeonik-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/public/Fonts/Aeonik/Aeonik-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/public/Fonts/Aeonik/Aeonik-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/public/Fonts/Aeonik/Aeonik-BoldItalic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/public/Fonts/Aeonik/Aeonik-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url('/public/Fonts/Aeonik/Aeonik-BlackItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
}


@font-face {
	font-family: "Sarabun-extralight";
	src: url("/public/Fonts/Sarabun-ExtraLight.ttf");
}
@font-face {
	font-family: "Sarabun-light";
	src: url("/public/Fonts/Sarabun-Light.ttf");
}
@font-face {
	font-family: "Sarabun-medium";
	src: url("/public/Fonts/Sarabun-Medium.ttf");
}
@font-face {
	font-family: "Sarabun";
	src: url("/public/Fonts/Sarabun-Regular.ttf");
}

@font-face {
	font-family: "Sarabun-semibold";
	src: url("/public/Fonts/Sarabun-SemiBold.ttf");
}

* {
	line-height: normal;
}

body {
	font-family: 'Aeonik', sans-serif;
	overflow-x: hidden;
}

li {
	list-style: none;
	font-family: 'Aeonik', sans-serif;}

.card-shadow {
	background: #f4fdf8;
	border-radius: 16px;
}

p {
	font-family: 'Aeonik', sans-serif;
}

.animateImage {
	position: absolute;
	white-space: nowrap;
	animation: floatImage 30s infinite alternate ease-in-out;
}

.animateIcon {
	animation: floatIcon 250ms ease;
}

@keyframes floatImage {
	/* from {
		left: -100%;
	}

	to {
		left: 100%;
	} */

	0% {
		left: 0%;
	}
	40% {
		left: 40%;
	}
	80% {
		left: -0%;
	}
	100% {
		left: -100%;
	}
}

@keyframes rotateIcon {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(180deg);
	}
}

/* old */

.nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 10%;
	background-color: white;
	width: 100%;
	position: relative;
	z-index: auto;
}

.fixed-nav {
	position: fixed;
}

.clipath {
	clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
}
/* index.css */
.bg-blend-color-dodge {
	mix-blend-mode: color-dodge;
	opacity: 0.30;
  }
  

  @keyframes bounce {
	0% {
		transform: translateY(-100%); /* Start off-screen */
		opacity: 0; /* Hidden at the start */
	}
	20%, 50%, 80%, 100% {
		transform: translateY(0); /* In place after moving downwards */
		opacity: 1; /* Fully visible */
	}
	40% {
		transform: translateY(-30px); /* Slight bounce upwards */
	}
	60% {
		transform: translateY(-15px); /* Smaller bounce */
	}
}

.bounce {
	animation: bounce 0.8s ease 0s; /* 800ms animation duration */
	animation-fill-mode: forwards; /* Ensure it stays in final position */
	opacity: 1; /* Start with it visible */
}

/* Styling the scrollbar */
::-webkit-scrollbar {
    width: 4px; /* Set the width of the scrollbar */
    height: 4px; /* Set the width of the scrollbar */
}

/* Styling the scrollbar thumb (the part you can drag) */
::-webkit-scrollbar-thumb {
    background-color: green; /* Thumb color */
    border-radius: 10px; /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    cursor: pointer; /* Cursor changes to pointer */
}

/* Adding hover effect to the scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
    background-color: darkgreen; /* Thumb color on hover */
}

/* Styling the scrollbar track (the background of the scrollbar) */
::-webkit-scrollbar-track {
    background-color: transparent; /* Track color */
}
  

/* Headings */
.h1 { font-size: 2.5rem; line-height: 3rem; }
.h2 { font-size: 2rem; line-height: 2.5rem; }
.h3 { font-size: 1.75rem; line-height: 2.25rem; }
.h4 { font-size: 1.5rem; line-height: 2rem; }
.h5 { font-size: 1.25rem; line-height: 1.75rem; }
.h6 { font-size: 1rem; line-height: 1.5rem; }

@media (min-width: 640px) {
    .h1 { font-size: 2.75rem; line-height: 3.25rem; }
    .h2 { font-size: 2.25rem; line-height: 2.75rem; }
    .h3 { font-size: 2rem; line-height: 2.5rem; }
    .h4 { font-size: 1.75rem; line-height: 2.25rem; }
    .h5 { font-size: 1.5rem; line-height: 2rem; }
    .h6 { font-size: 1.25rem; line-height: 1.75rem; }
}

@media (min-width: 768px) {
    .h1 { font-size: 3rem; line-height: 3.5rem; }
    .h2 { font-size: 2.5rem; line-height: 3rem; }
    .h3 { font-size: 2.25rem; line-height: 2.75rem; }
    .h4 { font-size: 2rem; line-height: 2.5rem; }
    .h5 { font-size: 1.75rem; line-height: 2.25rem; }
    .h6 { font-size: 1.5rem; line-height: 2rem; }
}

@media (min-width: 1024px) {
    .h1 { font-size: 3.5rem; line-height: 4rem; }
    .h2 { font-size: 3rem; line-height: 3.5rem; }
    .h3 { font-size: 2.75rem; line-height: 3.25rem; }
    .h4 { font-size: 2.5rem; line-height: 3rem; }
    .h5 { font-size: 2rem; line-height: 2.5rem; }
    .h6 { font-size: 1.75rem; line-height: 2.25rem; }
}

@media (min-width: 1280px) {
    .h1 { font-size: 4rem; line-height: 4.5rem; }
    .h2 { font-size: 3.5rem; line-height: 4rem; }
    .h3 { font-size: 3rem; line-height: 3.5rem; }
    .h4 { font-size: 2.75rem; line-height: 3.25rem; }
    .h5 { font-size: 2.25rem; line-height: 2.75rem; }
    .h6 { font-size: 2rem; line-height: 2.5rem; }
}

@media (min-width: 1920px) {
    .h1 { font-size: 4.5rem; line-height: 5rem; }
    .h2 { font-size: 4rem; line-height: 4.5rem; }
    .h3 { font-size: 3.5rem; line-height: 4rem; }
    .h4 { font-size: 3rem; line-height: 3.5rem; }
    .h5 { font-size: 2.75rem; line-height: 3.25rem; }
    .h6 { font-size: 2.5rem; line-height: 3rem; }
}

/* Subheadings */
.subheading-1 { font-size: 1.5rem; line-height: 2rem; }
.subheading-2 { font-size: 1.25rem; line-height: 1.75rem; }
.subheading-3 { font-size: 1rem; line-height: 1.5rem; }

@media (min-width: 640px) {
    .subheading-1 { font-size: 1.75rem; line-height: 2.25rem; }
    .subheading-2 { font-size: 1.5rem; line-height: 2rem; }
    .subheading-3 { font-size: 1.25rem; line-height: 1.75rem; }
}

@media (min-width: 768px) {
    .subheading-1 { font-size: 2rem; line-height: 2.5rem; }
    .subheading-2 { font-size: 1.75rem; line-height: 2.25rem; }
    .subheading-3 { font-size: 1.5rem; line-height: 2rem; }
}

@media (min-width: 1024px) {
    .subheading-1 { font-size: 2.25rem; line-height: 2.75rem; }
    .subheading-2 { font-size: 2rem; line-height: 2.5rem; }
    .subheading-3 { font-size: 1.75rem; line-height: 2.25rem; }
}

@media (min-width: 1280px) {
    .subheading-1 { font-size: 2.5rem; line-height: 3rem; }
    .subheading-2 { font-size: 2.25rem; line-height: 2.75rem; }
    .subheading-3 { font-size: 2rem; line-height: 2.5rem; }
}

/* texts */
.text-1 { font-size: 1rem; line-height: 1.5rem; }
.text-2 { font-size: 0.875rem; line-height: 1.25rem; }
.text-3 { font-size: 0.75rem; line-height: 1rem; }
.text-4 { font-size: 0.625rem; line-height: 0.875rem; }
.text-5 { font-size: 0.5rem; line-height: 0.75rem; }
.text-6 { font-size: 0.375rem; line-height: 0.625rem; }

@media (min-width: 640px) {
    .text-1 { font-size: 1.125rem; line-height: 1.75rem; }
    .text-2 { font-size: 1rem; line-height: 1.5rem; }
    .text-3 { font-size: 0.875rem; line-height: 1.25rem; }
    .text-4 { font-size: 0.75rem; line-height: 1rem; }
    .text-5 { font-size: 0.625rem; line-height: 0.875rem; }
    .text-6 { font-size: 0.5rem; line-height: 0.75rem; }
}

@media (min-width: 768px) {
    .text-1 { font-size: 1.25rem; line-height: 1.75rem; }
    .text-2 { font-size: 1.125rem; line-height: 1.5rem; }
    .text-3 { font-size: 1rem; line-height: 1.25rem; }
    .text-4 { font-size: 0.875rem; line-height: 1.25rem; }
    .text-5 { font-size: 0.75rem; line-height: 1rem; }
    .text-6 { font-size: 0.625rem; line-height: 0.875rem; }
}

@media (min-width: 1024px) {
    .text-1 { font-size: 1.375rem; line-height: 2rem; }
    .text-2 { font-size: 1.25rem; line-height: 1.75rem; }
    .text-3 { font-size: 1.125rem; line-height: 1.5rem; }
    .text-4 { font-size: 1rem; line-height: 1.5rem; }
    .text-5 { font-size: 0.875rem; line-height: 1.25rem; }
    .text-6 { font-size: 0.75rem; line-height: 1rem; }
}

@media (min-width: 1280px) {
    .text-1 { font-size: 1.5rem; line-height: 2.25rem; }
    .text-2 { font-size: 1.375rem; line-height: 2rem; }
    .text-3 { font-size: 1.25rem; line-height: 1.75rem; }
    .text-4 { font-size: 1.125rem; line-height: 1.75rem; }
    .text-5 { font-size: 1rem; line-height: 1.5rem; }
    .text-6 { font-size: 0.875rem; line-height: 1.25rem; }
}


@media (min-width: 1920px) {
    .subheading-1 { font-size: 2.75rem; line-height: 3.25rem; }
    .subheading-2 { font-size: 2.5rem; line-height: 3rem; }
    .subheading-3 { font-size: 2.25rem; line-height: 2.75rem; }
    
    .text-1 { font-size: 1.625rem; line-height: 2.5rem; }
    .text-2 { font-size: 1.5rem; line-height: 2.25rem; }
    .text-3 { font-size: 1.375rem; line-height: 2rem; }
    .text-4 { font-size: 1.25rem; line-height: 1.75rem; }
    .text-5 { font-size: 1.125rem; line-height: 1.5rem; }
    .text-6 { font-size: 1rem; line-height: 1.25rem; }
}


/* Paddings (All sides) */
.p-xs { padding: 0.5rem; }
.p-sm { padding: 0.75rem; }
.p-md { padding: 1rem; }
.p-lg { padding: 1.25rem; }
.p-xl { padding: 1.5rem; }
.p-2xl { padding: 1.75rem; }
.p-3xl { padding: 2rem; }
.p-4xl { padding: 2.25rem; }
.p-5xl { padding: 2.5rem; }
.p-6xl { padding: 2.75rem; }

/* Paddings X-axis */
.px-xs { padding-left: 0.5rem; padding-right: 0.5rem; }
.px-sm { padding-left: 0.75rem; padding-right: 0.75rem; }
.px-md { padding-left: 1rem; padding-right: 1rem; }
.px-lg { padding-left: 1.25rem; padding-right: 1.25rem; }
.px-xl { padding-left: 1.5rem; padding-right: 1.5rem; }
.px-2xl { padding-left: 1.75rem; padding-right: 1.75rem; }
.px-3xl { padding-left: 2rem; padding-right: 2rem; }
.px-4xl { padding-left: 2.25rem; padding-right: 2.25rem; }
.px-5xl { padding-left: 2.5rem; padding-right: 2.5rem; }
.px-6xl { padding-left: 2.75rem; padding-right: 2.75rem; }

/* Paddings Y-axis */
.py-xs { padding-top: 0.5rem; padding-bottom: 0.5rem; }
.py-sm { padding-top: 0.75rem; padding-bottom: 0.75rem; }
.py-md { padding-top: 1rem; padding-bottom: 1rem; }
.py-lg { padding-top: 1.25rem; padding-bottom: 1.25rem; }
.py-xl { padding-top: 1.5rem; padding-bottom: 1.5rem; }
.py-2xl { padding-top: 1.75rem; padding-bottom: 1.75rem; }
.py-3xl { padding-top: 2rem; padding-bottom: 2rem; }
.py-4xl { padding-top: 2.25rem; padding-bottom: 2.25rem; }
.py-5xl { padding-top: 2.5rem; padding-bottom: 2.5rem; }
.py-6xl { padding-top: 2.75rem; padding-bottom: 2.75rem; }

/* Responsive Media Queries */
@media (min-width: 640px) {
    .p-xs { padding: 0.75rem; }
    .p-sm { padding: 1rem; }
    .p-md { padding: 1.25rem; }
    .p-lg { padding: 1.5rem; }
    .p-xl { padding: 1.75rem; }
    .p-2xl { padding: 2rem; }
    .p-3xl { padding: 2.25rem; }
    .p-4xl { padding: 2.5rem; }
    .p-5xl { padding: 2.75rem; }
    .p-6xl { padding: 3rem; }

    .px-xs { padding-left: 0.75rem; padding-right: 0.75rem; }
    .px-sm { padding-left: 1rem; padding-right: 1rem; }
    .px-md { padding-left: 1.25rem; padding-right: 1.25rem; }
    .px-lg { padding-left: 1.5rem; padding-right: 1.5rem; }
    .px-xl { padding-left: 1.75rem; padding-right: 1.75rem; }
    .px-2xl { padding-left: 2rem; padding-right: 2rem; }
    .px-3xl { padding-left: 2.25rem; padding-right: 2.25rem; }
    .px-4xl { padding-left: 2.5rem; padding-right: 2.5rem; }
    .px-5xl { padding-left: 2.75rem; padding-right: 2.75rem; }
    .px-6xl { padding-left: 3rem; padding-right: 3rem; }

    .py-xs { padding-top: 0.75rem; padding-bottom: 0.75rem; }
    .py-sm { padding-top: 1rem; padding-bottom: 1rem; }
    .py-md { padding-top: 1.25rem; padding-bottom: 1.25rem; }
    .py-lg { padding-top: 1.5rem; padding-bottom: 1.5rem; }
    .py-xl { padding-top: 1.75rem; padding-bottom: 1.75rem; }
    .py-2xl { padding-top: 2rem; padding-bottom: 2rem; }
    .py-3xl { padding-top: 2.25rem; padding-bottom: 2.25rem; }
    .py-4xl { padding-top: 2.5rem; padding-bottom: 2.5rem; }
    .py-5xl { padding-top: 2.75rem; padding-bottom: 2.75rem; }
    .py-6xl { padding-top: 3rem; padding-bottom: 3rem; }
}

@media (min-width: 768px) {
    .p-xs { padding: 1rem; }
    .p-sm { padding: 1.25rem; }
    .p-md { padding: 1.5rem; }
    .p-lg { padding: 1.75rem; }
    .p-xl { padding: 2rem; }
    .p-2xl { padding: 2.25rem; }
    .p-3xl { padding: 2.5rem; }
    .p-4xl { padding: 2.75rem; }
    .p-5xl { padding: 3rem; }
    .p-6xl { padding: 3.25rem; }

    .px-xs { padding-left: 1rem; padding-right: 1rem; }
    .px-sm { padding-left: 1.25rem; padding-right: 1.25rem; }
    .px-md { padding-left: 1.5rem; padding-right: 1.5rem; }
    .px-lg { padding-left: 1.75rem; padding-right: 1.75rem; }
    .px-xl { padding-left: 2rem; padding-right: 2rem; }
    .px-2xl { padding-left: 2.25rem; padding-right: 2.25rem; }
    .px-3xl { padding-left: 2.5rem; padding-right: 2.5rem; }
    .px-4xl { padding-left: 2.75rem; padding-right: 2.75rem; }
    .px-5xl { padding-left: 3rem; padding-right: 3rem; }
    .px-6xl { padding-left: 3.25rem; padding-right: 3.25rem; }

    .py-xs { padding-top: 1rem; padding-bottom: 1rem; }
    .py-sm { padding-top: 1.25rem; padding-bottom: 1.25rem; }
    .py-md { padding-top: 1.5rem; padding-bottom: 1.5rem; }
    .py-lg { padding-top: 1.75rem; padding-bottom: 1.75rem; }
    .py-xl { padding-top: 2rem; padding-bottom: 2rem; }
    .py-2xl { padding-top: 2.25rem; padding-bottom: 2.25rem; }
    .py-3xl { padding-top: 2.5rem; padding-bottom: 2.5rem; }
    .py-4xl { padding-top: 2.75rem; padding-bottom: 2.75rem; }
    .py-5xl { padding-top: 3rem; padding-bottom: 3rem; }
    .py-6xl { padding-top: 3.25rem; padding-bottom: 3.25rem; }
}

@media (min-width: 1024px) {
    .p-xs { padding: 1.25rem; }
    .p-sm { padding: 1.5rem; }
    .p-md { padding: 1.75rem; }
    .p-lg { padding: 2rem; }
    .p-xl { padding: 2.25rem; }
    .p-2xl { padding: 2.5rem; }
    .p-3xl { padding: 2.75rem; }
    .p-4xl { padding: 3rem; }
    .p-5xl { padding: 3.25rem; }
    .p-6xl { padding: 3.5rem; }

    .px-xs { padding-left: 1.25rem; padding-right: 1.25rem; }
    .px-sm { padding-left: 1.5rem; padding-right: 1.5rem; }
    .px-md { padding-left: 1.75rem; padding-right: 1.75rem; }
    .px-lg { padding-left: 2rem; padding-right: 2rem; }
    .px-xl { padding-left: 2.25rem; padding-right: 2.25rem; }
    .px-2xl { padding-left: 2.5rem; padding-right: 2.5rem; }
    .px-3xl { padding-left: 2.75rem; padding-right: 2.75rem; }
    .px-4xl { padding-left: 3rem; padding-right: 3rem; }
    .px-5xl { padding-left: 3.25rem; padding-right: 3.25rem; }
    .px-6xl { padding-left: 3.5rem; padding-right: 3.5rem; }

    .py-xs { padding-top: 1.25rem; padding-bottom: 1.25rem; }
    .py-sm { padding-top: 1.5rem; padding-bottom: 1.5rem; }
    .py-md { padding-top: 1.75rem; padding-bottom: 1.75rem; }
    .py-lg { padding-top: 2rem; padding-bottom: 2rem; }
    .py-xl { padding-top: 2.25rem; padding-bottom: 2.25rem; }
    .py-2xl { padding-top: 2.5rem; padding-bottom: 2.5rem; }
    .py-3xl { padding-top: 2.75rem; padding-bottom: 2.75rem; }
    .py-4xl { padding-top: 3rem; padding-bottom: 3rem; }
    .py-5xl { padding-top: 3.25rem; padding-bottom: 3.25rem; }
    .py-6xl { padding-top: 3.5rem; padding-bottom: 3.5rem; }
}

@media (min-width: 1280px) {
    .p-xs { padding: 1.5rem; }
    .p-sm { padding: 1.75rem; }
    .p-md { padding: 2rem; }
    .p-lg { padding: 2.25rem; }
    .p-xl { padding: 2.5rem; }
    .p-2xl { padding: 2.75rem; }
    .p-3xl { padding: 3rem; }
    .p-4xl { padding: 3.25rem; }
    .p-5xl { padding: 3.5rem; }
    .p-6xl { padding: 3.75rem; }

    .px-xs { padding-left: 1.5rem; padding-right: 1.5rem; }
    .px-sm { padding-left: 1.75rem; padding-right: 1.75rem; }
    .px-md { padding-left: 2rem; padding-right: 2rem; }
    .px-lg { padding-left: 2.25rem; padding-right: 2.25rem; }
    .px-xl { padding-left: 2.5rem; padding-right: 2.5rem; }
    .px-2xl { padding-left: 2.75rem; padding-right: 2.75rem; }
    .px-3xl { padding-left: 3rem; padding-right: 3rem; }
    .px-4xl { padding-left: 3.25rem; padding-right: 3.25rem; }
    .px-5xl { padding-left: 3.5rem; padding-right: 3.5rem; }
    .px-6xl { padding-left: 3.75rem; padding-right: 3.75rem; }

    .py-xs { padding-top: 1.5rem; padding-bottom: 1.5rem; }
    .py-sm { padding-top: 1.75rem; padding-bottom: 1.75rem; }
    .py-md { padding-top: 2rem; padding-bottom: 2rem; }
    .py-lg { padding-top: 2.25rem; padding-bottom: 2.25rem; }
    .py-xl { padding-top: 2.5rem; padding-bottom: 2.5rem; }
    .py-2xl { padding-top: 2.75rem; padding-bottom: 2.75rem; }
    .py-3xl { padding-top: 3rem; padding-bottom: 3rem; }
    .py-4xl { padding-top: 3.25rem; padding-bottom: 3.25rem; }
    .py-5xl { padding-top: 3.5rem; padding-bottom: 3.5rem; }
    .py-6xl { padding-top: 3.75rem; padding-bottom: 3.75rem; }
}

@media (min-width: 1920px) {
    /* Paddings (All sides) */
    .p-xs { padding: 1.75rem; }
    .p-sm { padding: 2rem; }
    .p-md { padding: 2.25rem; }
    .p-lg { padding: 2.5rem; }
    .p-xl { padding: 2.75rem; }
    .p-2xl { padding: 3rem; }
    .p-3xl { padding: 3.25rem; }
    .p-4xl { padding: 3.5rem; }
    .p-5xl { padding: 3.75rem; }
    .p-6xl { padding: 4rem; }

    /* Paddings X-axis */
    .px-xs { padding-left: 1.75rem; padding-right: 1.75rem; }
    .px-sm { padding-left: 2rem; padding-right: 2rem; }
    .px-md { padding-left: 2.25rem; padding-right: 2.25rem; }
    .px-lg { padding-left: 2.5rem; padding-right: 2.5rem; }
    .px-xl { padding-left: 2.75rem; padding-right: 2.75rem; }
    .px-2xl { padding-left: 3rem; padding-right: 3rem; }
    .px-3xl { padding-left: 3.25rem; padding-right: 3.25rem; }
    .px-4xl { padding-left: 3.5rem; padding-right: 3.5rem; }
    .px-5xl { padding-left: 3.75rem; padding-right: 3.75rem; }
    .px-6xl { padding-left: 4rem; padding-right: 4rem; }

    /* Paddings Y-axis */
    .py-xs { padding-top: 1.75rem; padding-bottom: 1.75rem; }
    .py-sm { padding-top: 2rem; padding-bottom: 2rem; }
    .py-md { padding-top: 2.25rem; padding-bottom: 2.25rem; }
    .py-lg { padding-top: 2.5rem; padding-bottom: 2.5rem; }
    .py-xl { padding-top: 2.75rem; padding-bottom: 2.75rem; }
    .py-2xl { padding-top: 3rem; padding-bottom: 3rem; }
    .py-3xl { padding-top: 3.25rem; padding-bottom: 3.25rem; }
    .py-4xl { padding-top: 3.5rem; padding-bottom: 3.5rem; }
    .py-5xl { padding-top: 3.75rem; padding-bottom: 3.75rem; }
    .py-6xl { padding-top: 4rem; padding-bottom: 4rem; }
}
